<template>
  <div :class="headerbg" class="app-header header-shadow bg-info text-lighter">


    <div class="app-header__content">
      <div class="app-header-left " v-if="htlEur">
          <div class="mt-1 mb-1 mx-3 rates md-hidden-rate">
              <div class="rate"><div class="currency">HTL/EUR</div>{{htlEur.toFixed(4)}}</div>
          </div>
          <div class="mt-1 mb-1 mx-3 rates" v-if="htlUsd">
              <div class="rate"><div class="currency">HTL/USD</div>{{htlUsd.toFixed(4)}}</div>
          </div>
          <div class="mt-1 mb-1 mx-3 rates" v-if="htlBtc">
              <div class="rate"><div class="currency">HTL/BTC</div>{{htlBtc.toFixed(6)}}</div>
          </div>
          <div class="mt-1 mb-1 mx-3 rates md-hidden-rate" v-if="htlLtc">
              <div class="rate"><div class="currency">HTL/LTC</div>{{htlLtc.toFixed(4)}}</div>
          </div>
          <div class="mt-1 mb-1 mx-3 rates md-hidden-rate" v-if="eurUsd">
              <div class="rate"><div class="currency">EUR/USD</div>{{eurUsd.toFixed(2)}}</div>
          </div>
      </div>
      <div class="app-header-right">
          <div class="mt-1 mb-1 mx-3 rates">
              <div class="rate" v-if="available"><div class="currency">Available HTL</div>{{available|formatHtl}}</div>
              <div v-else><div class="currency"></div></div>
          </div>
        <UserArea />
      </div>
    </div>
    <div class="app-header__mobile-menu">
        <div class="mobile-btn d-md-none">
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" id="mobile-menu-btn"
              v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
        </div>
        <div class="header-container justify-content-center">
            <div class="mt-1 mb-1 ml-3 rates" v-if="htlUsd">
                <div class="rate"><div class="currency">HTL/USD</div>{{htlUsd.toFixed(4)}}</div>
            </div>
            <div class="mt-1 mb-1 ml-3 rates">
                <svg viewBox="0 0 32 32"><path d="M0,7.5l4,-2.5v22l-4,-2.5zM28,5l4,2.5v17l-4,2.5zM12,0v9h8v-9l4,2.5v10.5h-16v-10.5zM8,17h16v12.5l-4,2.5v-11h-8v11l-4,-2.5z"></path></svg>
            </div>
            <div class="mt-1 mb-1 ml-3 rates">
                <div class="rate"><div class="currency">Available</div>{{available|formatHtl}}</div>
            </div>

            <HeaderUserAreaMobile />
        </div>


    </div>


  </div>
</template>

<script>
import UserArea from "./HeaderUserArea";

import {mapGetters} from "vuex";
import HeaderUserAreaMobile from "@/components/common/HeaderUserAreaMobile";

export default {
  name: "Header",
  components: {
      HeaderUserAreaMobile,
    UserArea,
  },
  computed: {
      ...mapGetters({
          htlEur: 'prices/htlEur',
          htlBtc: 'prices/htlBtc',
          htlUsd: 'prices/htlUsd',
          btcUsd: 'prices/btcUsd',
          btcEur: 'prices/btcEur',
          eurUsd: 'prices/eurUsd',
          htlLtc: 'prices/htlLtc',
          available: 'user/available',
          isOpen: 'menu/isOpen'
      })
  },
  data() {
    return {
      isOpenMobileMenu: false
    };
  },
  props: {
    headerbg: String
  },
  methods: {
    toggleMobile(className) {
      const el = document.body;
      this.$store.commit('menu/changeMenu')

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },

    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    }
  }
};
</script>

<style scoped>
    @media (max-width: 768px) {
        .logo-src {
            display: flex !important;
            margin-left: -30%;
        }
        .logo-src > svg {
            fill: #1e3c72;
        }
        .logo-src > span {
            color: #1e3c72;
        }
    }

    .app-header__mobile-menu {
        align-items: center;
        justify-content: center;
    }

    .rates {
        max-height: 100%;
        text-align: center;
        align-self: center;
        white-space: nowrap;
    }

    .rate {
        font-size: 1.1rem;
        color: white;
    }

    .rate > .currency {
        font-size: 0.9rem;
    }

    .rates > svg {
        height: 32px;
        fill: whitesmoke;
    }

    .header-container {
        display: flex;
        justify-content: center;
    }

    .widget-content-right {
        align-self: end;
    }

</style>