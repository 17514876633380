<template>
  <div class="d-flex">
    <div class="header-btn-lg p-1 m-1 ml-2">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left language-flag">
              <b-dropdown toggle-class="p-0 mr-1" variant="link" no-caret right>
                  <span slot="button-content">
                    <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                      <div class="icon-wrapper-bg bg-focus"></div>
                      <div class="language-icon" v-if="language">
                        <country-flag :country.sync="currentFlag" class="opacity-8" />
                      </div>
                    </div>
                  </span>
                  <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                          <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
                          <div class="menu-header-content text-center text-white">
                              <h6 class="menu-header-subtitle mt-0"> Choose Language </h6>
                          </div>
                      </div>
                  </div>
                  <div class="flex">

                      <button type="button" tabindex="0" class="mx-1" v-for="lang in languages" :key="lang" @click="selectLang(lang)" >
                          <country-flag :country="getFlag(lang)" class="mr-3 opacity-8 flag-align" />
                      </button>
                  </div>
              </b-dropdown>
          </div>
          <div class="widget-content-left ml-1 header-user-info">
            <div class="widget-subheading text-wrap">{{fullName}}</div>
            <div class="widget-subheading">{{ getRank() }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-btn-lg">
      <button type="button" class="hamburger hamburger--elastic" v-on:click="logout">
        <font-awesome-icon icon="sign-out-alt" class="text-white" />
      </button>
    </div>

  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
    faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";

library.add(
  faAngleDown,
  faCalendarAlt,
  faTrashAlt,
  faCheck,
  faFileAlt,
  faCloudDownloadAlt,
  faFileExcel,
  faFilePdf,
  faFileArchive,
  faEllipsisH,
    faSignOutAlt
);

export default {
    components: {
        "font-awesome-icon": FontAwesomeIcon,
        CountryFlag
    },
    data: () => ({
        fill1: { gradient: ["#00b09b", "#96c93d"] },
        fill2: { gradient: ["#ff0844", "#ffb199"] },
        fill3: { gradient: ["#f6d365", "#fda085"] },
        showDrawerSection: false,
        languages: [
            'en',

            'hr',
            'ba',
            'rs',

            'gr',
            'me',

        ],
        language: 'en'
    }),
    computed: {
        ...mapGetters({
            fullName: 'user/fullName',
            rank: 'user/rank'
        }),
        currentFlag() {
            if (localStorage.getItem("lang") === 'en') return "GB"
            return localStorage.getItem("lang").toUpperCase()
        },
    },
    beforeCreate() {
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "en")
        }
    },
 
    methods: {
        getRank() {
            const rankName = [
                this.$i18n.t('rank-0'),
                this.$i18n.t('rank-1'),
                this.$i18n.t('rank-2'),
                this.$i18n.t('rank-3'),
                this.$i18n.t('rank-4'),
                this.$i18n.t('rank-5'),
                this.$i18n.t('rank-6'),
                this.$i18n.t('rank-7'),
                this.$i18n.t('rank-8'),
                this.$i18n.t('rank-9'),
                this.$i18n.t('rank-10'),
                this.$i18n.t('rank-11'),
            ];
            return rankName[this.rank];
        },
        getFlag(code) {
            if (code === 'en') return "GB"
            return code.toUpperCase()
        },
        logout() {
            if (sessionStorage.getItem('apollo-token')) {
                sessionStorage.removeItem('apollo-token')
                this.$router.push('/login')
            }
            else {

                localStorage.removeItem('apollo-token')
                this.$router.push('/login')
            }
        },
        selectLang(code) {
            if (this.languages.includes(code)) {
                this.language = code
                localStorage.setItem("lang", code)
                window.location.reload()
            }
        }
    }
};
</script>

<style scoped>
.icon-wrapper {
    justify-content: center;
}

.widget-heading {
    font-weight: 400!important;
    color: white;
    opacity: 1!important;
}

.widget-subheading {
    font-weight: 400!important;
    color: white;
    opacity: 1!important;
}

</style>