const state = {
    isOpen: false
};
const getters = {
    isOpen: state => state.isOpen
};
const actions = {
};
const mutations = {
    closeMenu(state) {
        state.isOpen = false
    },
    openMenu(state) {
        state.isOpen = true
    },
    changeMenu(state) {
        state.isOpen = !state.isOpen
    }
};
export const menu = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};