import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
    createApolloClient,
    restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client';

//infotrend
import {baseURL} from './config';

Vue.use(VueApollo);
const AUTH_TOKEN = 'apollo-token';
const httpEndpoint =
    baseURL+'/api/graphql';

export const filesRoot =
    process.env.VUE_APP_FILES_ROOT ||
    httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));
Vue.prototype.$filesRoot = filesRoot;
const defaultOptions = {
    httpEndpoint,
    wsEndpoint: null,
    tokenName: AUTH_TOKEN,
    persisting: false,
    websocketsOnly: false,
    ssr: false,
    getAuth: (tokenName) => {
        if (sessionStorage.getItem(tokenName)) {
            return 'Bearer: ' + sessionStorage.getItem(tokenName)
        }
        return 'Bearer: ' + localStorage.getItem(tokenName)
    }
};
export function createProvider(options = {}) {
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options
    });
    apolloClient.wsClient = wsClient;
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                fetchPolicy: 'cache-and-network',
            }
        },
        errorHandler(error) {
            console.log(
                '%cError',
                'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                error.message
            );
        }
    });
    return apolloProvider;
}
export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
    if (apolloClient.wsClient)
        restartWebsockets(apolloClient.wsClient);
    try {
        await apolloClient.resetStore();
    } catch (e) {
        console.log(
            '%cError on cache reset (login)',
            'color: orange;',
            e.message
        );
    }
}
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN);
    }
    if (apolloClient.wsClient)
        restartWebsockets(apolloClient.wsClient);
    try {
        await apolloClient.resetStore();
    } catch (e) {
        console.log(
            '%cError on cache reset (logout)',
            'color: orange;',
            e.message
        );
    }
}