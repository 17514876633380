<template>
  <div :class="sidebarbg" class="app-sidebar sidebar-shadow bg-dark text-lighter"
    @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')">
    <div class="app-header__logo">
      <div class="logo-src">
        <svg viewBox="0 0 32 32">
          <path
            d="M0,7.5l4,-2.5v22l-4,-2.5zM28,5l4,2.5v17l-4,2.5zM12,0v9h8v-9l4,2.5v10.5h-16v-10.5zM8,17h16v12.5l-4,2.5v-11h-8v11l-4,-2.5z">
          </path>
        </svg>
        <span>Hotelium</span>
      </div>
    </div>
    <div class="app-sidebar-content scroll-area" v-if="menu">
      <div class="menu-sticky-wrap">
        <div class="admin-menu-wrap mb-3">
          <div class="v-sidebar-menu sidebar-margin vsm-default admin-menu">
            <div class="vsm-list" v-if="isAdmin">
              <div class="vsm-header">Admin</div>
              <div class="vsm-item first-item">
                <a class="vsm-link" href="/admin/" tabindex="">
                  <i class="vsm-icon pe-7s-unlock"></i>
                  <span class="vsm-title">Admin</span>
                </a>
              </div>
              <div class="vsm-item first-item">
                <a class="vsm-link" v-on:click="adminClick()" tabindex="">
                  <i class="vsm-icon pe-7s-unlock"></i>
                  <span class="vsm-title">Admin v2</span>
                </a>
              </div>
              <div class="vsm-item first-item">
                <a class="vsm-link" v-on:click="vouchersClick()" tabindex="">
                  <i class="vsm-icon pe-7s-unlock"></i>
                  <span class="vsm-title">Vouchers</span>
                </a>
              </div>
              <div class="vsm-item first-item">
                <a class="vsm-link" v-on:click="reservationsClick()" tabindex="">
                  <i class="vsm-icon pe-7s-unlock"></i>
                  <span class="vsm-title">Reservations</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <sidebar-menu :menu="menu" @item-click="onItemClick" class="sidebar-margin">
        </sidebar-menu>

        <div class="admin-menu-wrap mb-3">
          <div class="v-sidebar-menu sidebar-margin vsm-default admin-menu">
            <div class="vsm-list">
              <div class="vsm-header"></div>
              <div class="vsm-header"></div>
              <div class="vsm-header"></div>
              <div class="vsm-header"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import { mapGetters } from "vuex";

export default {
  components: {
    SidebarMenu,
  },
  data() {
    return {
      showAdmin: false,
      sidebarActive: false,

      menu: [
        {
          icon: "pe-7s-graph2",
          header: true,
          title: this.$i18n.t("menu-hotelium"),
          hiddenOnCollapse: false,
        },
        {
          icon: "pe-7s-graph2",
          title: this.$i18n.t("menu-dashboard"),
          href: "/",
        },
        {
          icon: "pe-7s-user",
          title: this.$i18n.t("menu-profile"),
          href: "/profile",
        },
        {
          icon: "pe-7s-wallet",
          title: this.$i18n.t("menu-wallet"),
          href: "/wallet",
        },
        // {
        //     icon: "pe-7s-repeat",
        //     title: this.$i18n.t("menu-swap"),
        //     href: "/swap"
        // },
        {
          icon: "pe-7s-piggy",
          title: this.$i18n.t("menu-buy"),
          href: "/buy-packages",
        },
        {
          icon: "pe-7s-cart",
          title: this.$i18n.t("menu-packages"),
          href: "/packages",
        },
        {
          icon: "pe-7s-safe",
          title: this.$i18n.t("buy-freedom-program"),
          href: "/freedom-program",
        },
        {
          icon: "pe-7s-leaf",
          title: this.$i18n.t("my-freedom-program"),
          href: "/my-freedom-program",
        },
        {
          icon: "pe-7s-plane",
          title: this.$i18n.t("menu-resort-access"),
          href: "/travels",
        },
        {
          icon: "pe-7s-global",
          title: this.$i18n.t("menu-my-resort-access"),
          href: "/my-travels",
        },
        {
          icon: "pe-7s-portfolio",
          title: this.$i18n.t("menu-pool"),
          href: "/pool",
        },
        {
          header: true,
          title: this.$i18n.t("menu-org"),
        },
        {
          icon: "pe-7s-share",
          title: this.$i18n.t("menu-unilevel"),
          href: "/unilevel",
        },
        {
          icon: "pe-7s-network",
          title: this.$i18n.t("menu-binary"),
          href: "/binary",
        },
        {
          icon: "pe-7s-users",
          title: this.$i18n.t("menu-downline"),
          href: "/downline",
        },
        {
          icon: "pe-7s-box2",
          title: this.$i18n.t("menu-all-packages"),
          href: "/all-packages",
        },
        {
          icon: "pe-7s-graph1",
          title: this.$i18n.t("menu-statistics"),
          href: "/statistics",
        },

        {
          header: true,
          title: this.$i18n.t("menu-info"),
        },
        {
          icon: "pe-7s-diamond",
          title: this.$i18n.t("menu-diamond-club"),
          href: "/diamond-club-zlatibor",
        },
        {
          icon: "lnr-apartment",
          title: this.$i18n.t("menu-real-estate"),
          href: "/real-estate",
        },
        {
          icon: "lnr-heart-pulse",
          title: this.$i18n.t("menu-hearts"),
          href: "/hearts",
        },
        {
          icon: "lnr-graduation-hat",
          title: this.$i18n.t("menu-academy"),
          href: "/academy",
        },
        {
          icon: "lnr-bubble",
          title: this.$i18n.t("menu-support"),
          href: "/support",
        },
        {
          icon: "pe-7s-shopbag",
          title: this.$i18n.t("menu-shop"),
          href: "/shop",
        },
      ],
      menu2: [
        {
          header: true,
          title: "Admin",
          disabled: !this.isAdmin,
        },
        {
          icon: "lnr-lock",
          title: "Admin",
          href: "/admin",
          disabled: !this.isAdmin,
        },
        {
          icon: "lnr-lock",
          title: "Admin Vouchers",
          href: "/admin-vouchers",
          disabled: !this.isAdmin,
        },
      ],
      collapsed: false,

      windowWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      isOpen: "menu/isOpen",
      isAdmin: "user/isAdmin",
    }),
  },
  props: {
    sidebarbg: String,
  },
  methods: {
    onItemClick() {
      this.sidebarActive = false;
      this.$store.commit("menu/closeMenu");

      const el = document.body;
      el.classList.remove("closed-sidebar-open");
      const btn = document.getElementById("mobile-menu-btn");
      btn.classList.remove("is-active");
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleSidebarHover(add, className) {
      const el = document.body;
      this.sidebarActive = !this.sidebarActive;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth > "992") {
        if (add === "add") {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }
    },
    getWindowWidth() {
      const el = document.body;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "769") {
        el.classList.add("closed-sidebar");
      } else {
        el.classList.remove("closed-sidebar", "closed-sidebar-md");
      }
    },
    adminClick() {
      window.location.href =
        "adminv2?t=" + localStorage.getItem("apollo-token");
    },
    vouchersClick() {
      this.$router.push("/admin-vouchers");
    },
    reservationsClick() {
      this.$router.push("/admin-reservations");
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);

      this.getWindowWidth();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
};
</script>

<style>
.ps-container>.ps__scrollbar-y-rail {
  opacity: 0.8;
  display: block;
}
</style>
