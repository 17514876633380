<template>
  <v-app id="hotelium">
    <div class="app-container app-theme-white">
      <transition name="fade" mode="out-in" appear>
        <Header :headerbg="headercolor" />
      </transition>
      <transition name="fade" mode="out-in" appear>
        <Sidebar :sidebarbg="sidebarcolor" />
      </transition>
      <div class="app-main__outer">
        <div class="app-main__inner">
          <slot v-if="!loading"><v-progress-linear :indeterminate="true"></v-progress-linear></slot>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import gql from "graphql-tag";
library.add(faCog);
export default {
  name: "app",
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      headercolor: null,
      sidebarcolor: null,
      colors: [
        "bg-primary text-lighter",
        "bg-secondary text-lighter",
        "bg-success text-lighter",
        "bg-info text-lighter",
        "bg-warning text-darker",
        "bg-danger text-lighter",
        "bg-light text-darker",
        "bg-dark text-lighter",
        "bg-focus text-lighter",
        "bg-alternate text-lighter",
        "bg-vicious-stance text-lighter",
        "bg-midnight-bloom text-lighter",
          "bg-night-sky text-lighter",
          "bg-night-sky-hotelium text-lighter",
        "bg-slick-carbon text-lighter",
        "bg-asteroid text-lighter",
        "bg-royal text-lighter",
        "bg-warm-flame text-darker",
        "bg-night-fade text-darker",
        "bg-sunny-morning text-darker",
        "bg-tempting-azure text-darker",
        "bg-amy-crisp text-darker",
        "bg-heavy-rain text-darker",
        "bg-mean-fruit text-darker",
        "bg-malibu-beach text-darker",
        "bg-deep-blue text-darker",
        "bg-ripe-malin text-lighter",
        "bg-arielle-smile text-lighter",
        "bg-plum-plate text-lighter",
        "bg-happy-fisher text-darker",
        "bg-happy-itmeo text-darker",
        "bg-mixed-hopes text-lighter",
        "bg-strong-bliss text-lighter",
        "bg-grow-early text-lighter",
        "bg-love-kiss text-lighter",
        "bg-premium-dark text-lighter",
        "bg-happy-green text-lighter"
      ],
        loading: true,
        errors: false,
        hotelium: null,
        pricesConnection: null,
        ltcConnection: null,
        prices: {
            htlUsd: 0,
            htlEur: 0,
            htlBtc: 0,
            btcUsd: 0,
            btcEur: 0,
            eurUsd: 0
        }
    };
  },
  mounted() {
      this.loading = false;
  },
    methods: {
        updatePrice(price) {
            this.$store.commit('prices/updatePrice', {
                price: price
            })
        }
    },
    created() {
        setInterval(() => {
            if (this.pricesConnection) {
                this.pricesConnection.send("")
            }
            else {
                this.$apollo.queries.hotelium.refetch()
            }
        }, 30000)
        this.$apollo.query({
            query: gql`query{ltcBtc}`
        }).then((response) => this.$store.commit('prices/updateLtc', response.data.ltcBtc))
        setInterval(() => {
            this.$apollo.query({
                query: gql`query{ltcBtc}`
            }).then((response) => this.$store.commit('prices/updateLtc', response.data.ltcBtc))
        }, 5000)
    },
    apollo: {
        hotelium: {
            query: gql`query {
                viewer {
                    id, 
                    createdAt,
                    username,
                    firstName,
                    lastName,
                    email,
                    inviteId,
                    inviteLeg,
                    totals {
                        rank,
                        tokens
                    }
                },
                viewerIsAdmin,
                viewerIsEditor,
                websocketUrl,
                country
            }`,
            update: data => data,
            result({data}) {
                this.$store.commit('user/saveUser', {
                    user: data.viewer
                })
                this.pricesConnection = new WebSocket(data.websocketUrl)
                this.pricesConnection.onmessage = this.updatePrice;
            },
            updatePrice({price}) {
                this.$store.commit('prices/updatePrice', {
                    price: price
                })
            }
        }
    }
};
</script>
