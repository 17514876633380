import Vue from 'vue'
import Vuex from 'vuex'
import { createLogger } from 'vuex'
import {prices} from "@/store/modules/prices";
import {user} from "@/store/modules/user";
import {menu} from "@/store/modules/menu"
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
    modules: {
        prices,
        user,
        menu
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
})