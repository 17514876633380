const getters = {
    fullName: state => state.user.firstName + " " + state.user.lastName,
    available: state => state.user.totals.tokens,
    country: state => state.user.country,
    rank: state => state.user.totals.rank,
    userId: state => state.user.id,
    username: state => state.user.username,
    firstName: state => state.user.firstName,
    lastName: state => state.user.lastName,
    isAdmin: state => state.isAdmin
};
const actions= {
};
const state = {
    user : {
        firstName: "",
        lastName: "",
        username: "",
        totals: {
            available: 0
        },
        isAdmin: false
    },
    isAdmin: false
};
const mutations = {
    saveUser(state, payload) {
        state.user = payload.user
    },
    updateAvailable(state, payload) {
        state.user.totals.tokens = payload.tokens
    },
    setAdmin(state) {
        state.isAdmin = true
    }
};
export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}