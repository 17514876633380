import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import sl from './locales/sl.json'
import rs from './locales/rs.json'
import gr from './locales/gr.json'
import hr from './locales/hr.json'
Vue.use(VueI18n)
const languages = {
    en: en,
    si: sl,
    rs: rs,
    gr: gr,
    hr: hr,
    ba: hr,
    me: hr
}
export default languages
