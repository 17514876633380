const state = {
    prices: {
        htlUsd: 0,
        htlEur: 0,
        htlBtc: 0,
        btcUsd: 0,
        btcEur: 0,
        eurUsd: 0,
        ltcBtc: 0.001,
    },
    rate: null,
    btcRate: null,
    usdRate: null,
};
const getters = {
    htlUsd: state => state.prices.htlUsd,
    htlEur: state => state.prices.htlEur,
    htlBtc: state => state.prices.htlBtc,
    btcUsd: state => state.prices.btcUsd,
    btcEur: state => state.prices.btcEur,
    eurUsd: state => state.prices.eurUsd,
    htlLtc: state => state.prices.htlBtc / state.prices.ltcBtc,
    rate: state => state.rate,
    btcRate: state => state.btcRate,
    usdRate: state => state.usdRate
};
const actions = {
};
const mutations = {
    updatePrice(state, payload) {
        const data = payload.price.data.split("~")
        switch (data[0]) {
            case "BTCUSD":
                state.prices.btcUsd = parseFloat(data[1])
                return
            case "BTCEUR":
                state.prices.btcEur = parseFloat(data[1])
                return
            case "HTLBTC":
                state.prices.htlBtc = parseFloat(data[1])
                state.btcRate = payload.price.data
                return
            case "HTLUSD":
                state.prices.htlUsd = parseFloat(data[1])
                state.usdRate = payload.price.data
                return
            case "HTLEUR":
                state.prices.htlEur = parseFloat(data[1])
                state.rate = payload.price.data
                return
            case "EURUSD":
                state.prices.eurUsd = parseFloat(data[1])
                return
            default:
                return;
        }
    },
    updateLtc(state, payload) {
        state.prices.ltcBtc = payload
    }
};
export const prices = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};