import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);
let router = new Router({
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: [
        {
            path: '/login/:uid?',
            name: 'login',
            meta: { layout: 'userpages' },
            component: () => import('../Pages/UserPages/Login.vue'),
        },
        {
            path: '/sign-up/:inviteId?',
            name: 'sign-up',
            meta: { layout: 'userpages' },
            component: () => import('../Pages/UserPages/SignUp.vue'),
        },
        {
            path: '/password-recovery/:token?',
            name: 'password',
            meta: { layout: 'userpages' },
            component: () => import('../Pages/UserPages/ForgotPassword.vue'),
        },
        {
            path: '/confirm-email/:token?',
            name: 'confirm-email',
            meta: { layout: 'userpages' },
            component: () => import('../Pages/UserPages/ConfirmEmailPage.vue'),
        },
        {
            path: '/kyc',
            name: 'kyc',
            meta: { layout: 'userpages', requiresAuth: true },
            component: () => import('../Pages/UserPages/KYCPage.vue'),
        },
        {
            path: "/",
            name: 'dashboard',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Dashboard')
        },
        {
            path: "/profile",
            name: 'profile',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Profile')
        },
        {
            path: "/wallet",
            name: 'wallet',
            meta: { requiresAuth: true },
            component: () => import('../Pages/MyWallet')
        },
        {
            path: "/swap",
            name: 'swap',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Swap')
        },
        {
            path: "/freedom-program",
            name: 'freedom-program',
            meta: { requiresAuth: true },
            component: () => import('../Pages/FreedomProgramPage')
        },
        {
            path: "/my-freedom-program",
            name: 'my-freedom-program',
            meta: { requiresAuth: true },
            component: () => import('../Pages/MyFreedomProgramPage')
        },
        {
            path: "/buy-packages",
            name: 'buy-package',
            meta: { requiresAuth: true },
            component: () => import('../Pages/BuyPackagePage')
        },
        {
            path: "/packages",
            name: 'packages',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Packages')
        },
        {
            path: "/pool",
            name: 'pool',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Pool')
        },
        {
            path: "/unilevel",
            name: 'unilevel',
            meta: { requiresAuth: true },
            component: () => import('../Pages/UnilevelPage')
        },
        {
            path: "/binary/:userId?",
            name: 'binary',
            meta: { requiresAuth: true },
            component: () => import('../Pages/BinaryPage')
        },
        {
            path: "/downline/:userId?",
            name: 'downline',
            meta: { requiresAuth: true },
            component: () => import('../Pages/DownlinePage')
        },
        {
            path: "/all-packages",
            name: 'all-packages',
            meta: { requiresAuth: true },
            component: () => import('../Pages/AllPackages')
        },
        {
            path: "/real-estate",
            name: 'real-estate',
            meta: { requiresAuth: true },
            component: () => import('../Pages/RealEstatePage')
        },
        {
            path: "/hearts",
            name: 'hearts',
            meta: { requiresAuth: true },
            component: () => import('../Pages/HeartsPage')
        },
        {
            path: "/academy",
            name: 'academy',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Academy')
        },
        {
            path: "/support",
            name: 'support',
            meta: { requiresAuth: true },
            component: () => import('../Pages/SupportPage')
        },
        {
            path: "/statistics",
            name: 'statistics',
            meta: { requiresAuth: true },
            component: () => import('../Pages/Statistics')
        },
        {
            path: "/shop",
            name: 'shop',
            meta: { requiresAuth: true },
            component: () => import('../Pages/MerchantPage')
        },
        {
            path: "/admin",
            name: 'admin',
            meta: { admin: true }
        },
        {
            path: "/admin-vouchers",
            name: 'adminVouchers',
            meta: { admin: true },
            component: () => import('../Pages/AdminVouchersPage')
        },
        {
            path: "/admin-reservations",
            name: 'adminReservations',
            meta: { admin: true },
            component: () => import('../Pages/AdminReservationsPage')
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: () => import('../Pages/UserPages/LoginAs')
        },
        {
            path: "/diamond-club-zlatibor",
            name: "diamond-club",
            props: true,
            meta: { requiresAuth: true },
            component: () => import('../Pages/DiamondClubPage')
        },
        {
            path: "/travels",
            name: "travels",
            props: true,
            meta: { requiresAuth: true },
            component: () => import('../Pages/TravelsPage')
        },
        {
            path: "/my-travels",
            name: "my-travels",
            props: true,
            meta: { requiresAuth: true },
            component: () => import('../Pages/MyTravelsPage')
        }
    ]
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('apollo-token')) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})
router.afterEach((to) => {
    if (to.name != 'adminReservations' && to.name != 'adminVouchers' && to.matched.some(record => {
        return record.meta.admin
    })) {
        window.location.href = '/admin/'
    }
})
export default router;